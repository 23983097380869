<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
        <v-row>

<v-col cols="12" md="2">
          <v-select
            label="Select Venue"
            v-model="Selvenue"
            :items="Venue"
            item-text="venue_name"
            item-value="venue_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
                            <v-dialog
                              ref="dialogdatefrom"
                              v-model="modaldatefrom"
                              :return-value.sync="datefrom"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  hide-details
                                  dense
                                  v-model="datefrom"
                                  label="From Date"
                                  prepend-icon="mdi-calendar"
                                  :rules="Vdatebulk"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="datefrom" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modaldatefrom = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialogdatefrom.save(datefrom)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

<v-col cols="12" sm="6" md="3">
                            <v-dialog
                              ref="dialogdateto"
                              v-model="modaldateto"
                              :return-value.sync="dateto"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  hide-details
                                  dense
                                  v-model="dateto"
                                  label="To Date"
                                  prepend-icon="mdi-calendar"
                                  :rules="Vdatebulk"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="dateto" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modaldateto = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialogdateto.save(dateto)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          

         <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
        <v-col cols="12" md="2" v-if="desserts.length > 0">
          <export-excel :data="desserts" type="button">
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-col>

        </v-row>
    </v-card>

<v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >

      <template v-slot:top>
          <v-card style="position: sticky; top: 0px; z-index: 5" flat>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
                style="max-width: 400px"
              ></v-text-field>
            </v-card-actions>
          </v-card>
      </template>
    
      </v-data-table>
</v-card>

  </div>
</template>

<script>
export default {
data(){
   return{
    datefrom: new Date().toISOString().substr(0, 10),
    modaldatefrom: false,
     dateto: new Date().toISOString().substr(0, 10),
    modaldateto: false,
    search: "",
   SelVendor: "",
   Selvenue:"",
   Venue:[],
      Vendor: [],
      TDselected: [],
      desserts: [],
       headers: [
        {
          text: "AppID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5 w-140",
        },
        {
          text: "Name",
          value: "names",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

     
        {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "Barcode",
          value: "qrcode",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Date",
          value: "scann_date",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "IN/OUT",
          value: "scanner",
          align: "left",
          class: "blue lighten-5",
        },


       
      ],
   };

},
mounted(){
    this.$store.commit("setPageTitle", "Scann Report");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-plus-outline");
    this.bindVenue(this.$store.state.eventInfo.EventId);
},
methods:{


bindVenue: async function (EventID) {
      await this.$axios
        .get(
          "media/mediavenue/" +
            EventID
            
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Venue = res.data.result;
            this.Venue.splice(0, 0, { venue_id: "0", venue_name: "All" });
          }
        });
    },
    
    binddata:async function(venue_id,fdate,tdate){
      await this.$axios
        .get(
          "Scanner/scanngetreport/" +
            this.$store.state.eventInfo.EventId + "/" +
      venue_id + "/" + fdate + "/" + tdate
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            console.log(res.data.result);
            this.desserts=res.data.result;
          }
        });

    },
    btnsearch:function()
    {
        
        this.TDselected = [];
      this.desserts = [];
      this.binddata(this.Selvenue,this.datefrom,this.dateto);
    },

    

},
}
</script>

<style>

</style>